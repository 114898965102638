<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div v-show="!isEmpty" class="py-3 container inner-container">
    <h1 class="dynamic-sub-title-search">{{ $t("search.result") }}</h1>
    <p v-if="isEmpty" class="text-center pa-15 searchEmpty">
      {{ $t("search.not.found", { name: query }) }}
    </p>
    <div v-else class="file-box my-md-8 my-3">
      <v-container fluid class="">
        <v-row>
          <v-col cols="12" sm="6" md="4" v-for="item in list" :key="item.id">
            <router-link
              class="text-overflow-ep-1 word-break--break-word tmw-16-15"
              :to="{
                name: 'page',
                params: { siteCode, code: item.code },
              }"
              >{{ item.name }}</router-link
            >
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-if="has_next" class="d-flex justify-center mt-6">
      <Main-btn @click="more">{{ $t("action.look-more") }}</Main-btn>
    </div>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import indexList from "@/components/page/indexList.js";
export default {
  mixins: [indexList],
  props: {
    query: String,
  },
  components: {},
  computed: {
    siteCode() {
      return this.$route.params.siteCode;
    },
    indexApi() {
      return this.$api.page.public.index;
    },
    otherParams() {
      return {
        per: 9,
        search: this.query,
      };
    },
  },
};
</script>